// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Axios = require("axios");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Config = require("../../config").default;
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Core__Result = require("@rescript/core/src/Core__Result.bs.js");
var ReasonPromise = require("../ReasonPromise.bs.js");
var Utils__Promise = require("../../utils/Utils__Promise.bs.js");

var config = Config;

var apiPath = config.apiPath;

var instance = Axios.create({
      withCredentials: true
    });

function roleFromJs(x) {
  switch (x) {
    case "agent" :
        return "agent";
    case "client" :
        return "client";
    default:
      return ;
  }
}

function parse(impersonated, js) {
  var role = roleFromJs(js.role);
  if (role !== undefined) {
    return {
            TAG: "Ok",
            _0: {
              id: js.id,
              role: role,
              email: js.email,
              impersonated: impersonated
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: {
              NAME: "INCOMPLETE_DATA",
              VAL: "unknown role: " + (js.role + " in login response")
            }
          };
  }
}

var UserInfo = {
  roleFromJs: roleFromJs,
  parse: parse
};

function login(email, password, rememberMe) {
  var postData_username = email.trim();
  var postData = {
    username: postData_username,
    password: password,
    rememberMe: rememberMe
  };
  return ReasonPromise.map(Utils__Promise.fromJsPromise(instance.post("/api/login", postData)), (function (x) {
                if (x.TAG !== "Ok") {
                  return {
                          TAG: "Error",
                          _0: "INVALID_EMAIL_PASSWORD"
                        };
                }
                var data = x._0.data;
                if (data == null) {
                  return {
                          TAG: "Error",
                          _0: {
                            NAME: "INCOMPLETE_DATA",
                            VAL: "was none"
                          }
                        };
                }
                var impersonated = Core__Option.getOr(Caml_option.nullable_to_opt(data.impersonated), false);
                return Core__Result.map((function (__x) {
                                return parse(impersonated, __x);
                              })(data.user), (function (userInfo) {
                              return {
                                      accessToken: data.accessToken,
                                      expiresIn: data.expiresIn,
                                      user: userInfo,
                                      impersonated: Core__Option.getOr(Caml_option.nullable_to_opt(data.impersonated), false)
                                    };
                            }));
              }));
}

exports.config = config;
exports.apiPath = apiPath;
exports.instance = instance;
exports.UserInfo = UserInfo;
exports.login = login;
/* config Not a pure module */
