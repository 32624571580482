// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Scalars = require("../../scalars/Scalars.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Formality = require("../../vendor/re-formality/Formality.bs.js");
var ReasonPromise = require("../../reason/ReasonPromise.bs.js");
var Utils__Promise = require("../../utils/Utils__Promise.bs.js");
var Formality__Debouncer = require("../../vendor/re-formality/Formality__Debouncer.bs.js");
var Formality__ReactUpdate = require("../../vendor/re-formality/Formality__ReactUpdate.bs.js");

var validators_password = {
  strategy: "OnFirstBlur",
  validate: (function (param, param$1) {
      var password = param.password;
      if (password === "") {
        return {
                TAG: "Error",
                _0: "is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: password
              };
      }
    })
};

var validators_email = {
  strategy: "OnFirstBlur",
  validate: (function (param, param$1) {
      var email = param.email;
      if (email === "") {
        return {
                TAG: "Error",
                _0: "is required"
              };
      }
      var ok = Scalars.EmailAddress.validate(email);
      if (ok.TAG === "Ok") {
        return ok;
      } else {
        return {
                TAG: "Error",
                _0: "must be a valid email address"
              };
      }
    }),
  validateAsync: Formality__Debouncer.make(700, (function (param) {
          var dispatch = param[2];
          var value = param[0];
          var validate = function (email, param) {
            return Utils__Promise.toJsPromise(ReasonPromise.map(ReasonPromise.map(param.queryEmailAddress(email), (function (v) {
                                  if (v.TAG !== "Ok") {
                                    return {
                                            TAG: "Error",
                                            _0: "there was an error connecting to our servers"
                                          };
                                  }
                                  var tmp = v._0;
                                  if (tmp === "AVAILABLE" || tmp === "REGISTERED") {
                                    if (tmp === "AVAILABLE") {
                                      return {
                                              TAG: "Error",
                                              _0: "email is not registered"
                                            };
                                    } else {
                                      return {
                                              TAG: "Ok",
                                              _0: email
                                            };
                                    }
                                  } else {
                                    return {
                                            TAG: "Error",
                                            _0: "there was an error connecting to our servers"
                                          };
                                  }
                                })), (function (v) {
                              return {
                                      TAG: "Ok",
                                      _0: v
                                    };
                            })));
          };
          Formality.Async.validateAsyncWithMetadata(value, param[1], validate, (function (res) {
                  dispatch({
                        TAG: "ApplyAsyncResultForEmailField",
                        _0: value,
                        _1: res
                      });
                }));
        })),
  eq: Caml_obj.equal
};

var validators = {
  rememberMe: undefined,
  password: validators_password,
  email: validators_email
};

function initialFieldsStatuses(_input) {
  return {
          rememberMe: "Pristine",
          password: "Pristine",
          email: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            rememberMe: "Pristine",
            password: "Pristine",
            email: "Pristine"
          },
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match_0 = {
    TAG: "Ok",
    _0: input.rememberMe
  };
  var match = fieldsStatuses.password;
  var tmp;
  tmp = typeof match !== "object" ? validators.password.validate(input, metadata) : match._0;
  var value = fieldsStatuses.email;
  var tmp$1;
  tmp$1 = typeof value !== "object" ? ({
        NAME: "Result",
        VAL: validators.email.validate(input, metadata)
      }) : (
      value.TAG === "Dirty" ? ({
            NAME: "Result",
            VAL: value._0
          }) : ({
            NAME: "Validating",
            VAL: value._0
          })
    );
  var rememberMeResult = match_0;
  var exit = 0;
  var emailResult = tmp$1;
  if (typeof emailResult === "object") {
    if (emailResult.NAME === "Validating") {
      return {
              TAG: "Validating",
              fieldsStatuses: {
                rememberMe: {
                  TAG: "Dirty",
                  _0: rememberMeResult,
                  _1: "Hidden"
                },
                password: {
                  TAG: "Dirty",
                  _0: tmp,
                  _1: "Shown"
                },
                email: emailResult.NAME === "Result" ? ({
                      TAG: "Dirty",
                      _0: emailResult.VAL,
                      _1: "Shown"
                    }) : ({
                      TAG: "Validating",
                      _0: emailResult.VAL
                    })
              },
              collectionsStatuses: undefined
            };
    }
    exit = 2;
  } else {
    exit = 2;
  }
  if (exit === 2 && rememberMeResult.TAG === "Ok") {
    var passwordResult = tmp;
    if (passwordResult.TAG === "Ok") {
      var match$1 = tmp$1;
      if (typeof match$1 === "object" && match$1.NAME === "Result") {
        var emailResult$1 = match$1.VAL;
        if (emailResult$1.TAG === "Ok") {
          return {
                  TAG: "Valid",
                  output: {
                    email: emailResult$1._0,
                    password: passwordResult._0,
                    rememberMe: rememberMeResult._0
                  },
                  fieldsStatuses: {
                    rememberMe: {
                      TAG: "Dirty",
                      _0: rememberMeResult,
                      _1: "Hidden"
                    },
                    password: {
                      TAG: "Dirty",
                      _0: passwordResult,
                      _1: "Shown"
                    },
                    email: {
                      TAG: "Dirty",
                      _0: emailResult$1,
                      _1: "Shown"
                    }
                  },
                  collectionsStatuses: undefined
                };
        }
        
      }
      
    }
    
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            rememberMe: {
              TAG: "Dirty",
              _0: rememberMeResult,
              _1: "Hidden"
            },
            password: {
              TAG: "Dirty",
              _0: tmp,
              _1: "Shown"
            },
            email: {
              TAG: "Dirty",
              _0: tmp$1.VAL,
              _1: "Shown"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurRememberMeField" :
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.rememberMe, state.fieldsStatuses.rememberMe, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rememberMe: status,
                                  password: init.password,
                                  email: init.email
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurPasswordField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.password, validators_password, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rememberMe: init.rememberMe,
                                  password: status,
                                  email: init.email
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurEmailField" :
                  var result$2 = Formality.Async.validateFieldOnBlurWithMetadata(state.input, metadata, state.fieldsStatuses.email, validators_email, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rememberMe: init.rememberMe,
                                  password: init.password,
                                  email: status
                                };
                        }));
                  if (result$2 === undefined) {
                    return "NoUpdate";
                  }
                  var value = result$2.email;
                  if (typeof value === "object" && value.TAG !== "Dirty") {
                    var value$1 = value._0;
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            },
                            _1: (function (param) {
                                validators_email.validateAsync([
                                      value$1,
                                      metadata,
                                      param.dispatch
                                    ]);
                              })
                          };
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: result$2,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
                  break;
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  switch (match$1.TAG) {
                    case "Valid" :
                        var output = match$1.output;
                        var error = state.formStatus;
                        var tmp;
                        tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : error._0;
                        return {
                                TAG: "UpdateWithSideEffects",
                                _0: {
                                  input: state.input,
                                  fieldsStatuses: match$1.fieldsStatuses,
                                  collectionsStatuses: match$1.collectionsStatuses,
                                  formStatus: {
                                    TAG: "Submitting",
                                    _0: tmp
                                  },
                                  submissionStatus: "AttemptedToSubmit"
                                },
                                _1: (function (param) {
                                    var dispatch = param.dispatch;
                                    onSubmit(output, {
                                          notifyOnSuccess: (function (input) {
                                              dispatch({
                                                    TAG: "SetSubmittedStatus",
                                                    _0: input
                                                  });
                                            }),
                                          notifyOnFailure: (function (error) {
                                              dispatch({
                                                    TAG: "SetSubmissionFailedStatus",
                                                    _0: error
                                                  });
                                            }),
                                          reset: (function () {
                                              dispatch("Reset");
                                            }),
                                          dismissSubmissionResult: (function () {
                                              dispatch("DismissSubmissionResult");
                                            })
                                        });
                                  })
                              };
                    case "Invalid" :
                        return {
                                TAG: "Update",
                                _0: {
                                  input: state.input,
                                  fieldsStatuses: match$1.fieldsStatuses,
                                  collectionsStatuses: match$1.collectionsStatuses,
                                  formStatus: "Editing",
                                  submissionStatus: "AttemptedToSubmit"
                                }
                              };
                    case "Validating" :
                        return {
                                TAG: "Update",
                                _0: {
                                  input: state.input,
                                  fieldsStatuses: match$1.fieldsStatuses,
                                  collectionsStatuses: match$1.collectionsStatuses,
                                  formStatus: state.formStatus,
                                  submissionStatus: state.submissionStatus
                                }
                              };
                    
                  }
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateRememberMeField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.rememberMe, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rememberMe: status,
                                            password: init.password,
                                            email: init.email
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdatePasswordField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, state.fieldsStatuses.password, state.submissionStatus, validators_password, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rememberMe: init.rememberMe,
                                            password: status,
                                            email: init.email
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateEmailField" :
                  var nextInput$2 = action._0(state.input);
                  var nextFieldsStatuses = Formality.Async.validateFieldOnChangeInOnChangeModeWithMetadata(nextInput$2, metadata, state.fieldsStatuses.email, state.submissionStatus, validators_email, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rememberMe: init.rememberMe,
                                  password: init.password,
                                  email: status
                                };
                        }));
                  var value$2 = nextFieldsStatuses.email;
                  if (typeof value$2 === "object" && value$2.TAG !== "Dirty") {
                    var value$3 = value$2._0;
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              input: nextInput$2,
                              fieldsStatuses: nextFieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            },
                            _1: (function (param) {
                                validators_email.validateAsync([
                                      value$3,
                                      metadata,
                                      param.dispatch
                                    ]);
                              })
                          };
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: nextFieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "ApplyAsyncResultForEmailField" :
                  var validator = validators_email;
                  var x = state.fieldsStatuses.email;
                  if (typeof x !== "object") {
                    return "NoUpdate";
                  }
                  if (x.TAG === "Dirty") {
                    return "NoUpdate";
                  }
                  if (!validator.eq(x._0, action._0)) {
                    return "NoUpdate";
                  }
                  var init = state.fieldsStatuses;
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: {
                              rememberMe: init.rememberMe,
                              password: init.password,
                              email: {
                                TAG: "Dirty",
                                _0: action._1,
                                _1: "Shown"
                              }
                            },
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                rememberMe: "Pristine",
                                password: "Pristine",
                                email: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                rememberMe: "Pristine",
                                password: "Pristine",
                                email: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: map(error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateRememberMe: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateRememberMeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updatePassword: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdatePasswordField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateEmail: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateEmailField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurRememberMe: (function () {
              dispatch("BlurRememberMeField");
            }),
          blurPassword: (function () {
              dispatch("BlurPasswordField");
            }),
          blurEmail: (function () {
              dispatch("BlurEmailField");
            }),
          rememberMeResult: Formality.exposeFieldResult(state.fieldsStatuses.rememberMe),
          passwordResult: Formality.exposeFieldResult(state.fieldsStatuses.password),
          emailResult: Formality.Async.exposeFieldResult(state.fieldsStatuses.email),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.rememberMe;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.password;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.email;
              if (typeof tmp$2 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              switch (match.TAG) {
                case "Valid" :
                    return true;
                case "Invalid" :
                    return false;
                case "Validating" :
                    return ;
                
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var debounceInterval = 700;

var initialCollectionsStatuses;

exports.debounceInterval = debounceInterval;
exports.validators = validators;
exports.initialFieldsStatuses = initialFieldsStatuses;
exports.initialCollectionsStatuses = initialCollectionsStatuses;
exports.initialState = initialState;
exports.validateForm = validateForm;
exports.useForm = useForm;
/* validators Not a pure module */
